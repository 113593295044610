import { UserRole } from '@lasso/shared/hooks'

export type RevertImpersonationResponse = {
  access_token: string
}

export type AccountLoginRequestType = {
  userName: string
  password: string
}

export type AccountSendForgotPasswordRequestType = {
  email: string
}

export type AccountResetPasswordRequestType = {
  userId: string
  password: string
  confirmPassword: string
  code: string
}

export type AccountChangePasswordRequestType = {
  newPassword: string
  oldPassword: string
  confirmPassword: string
}

export enum AccountOAuthProviderEnum {
  google = 'google',
  microsoft = 'microsoft',
}

export type AccountLoginResponse = {
  access_token: string
  refresh_token?: string
  token_type: 'bearer'
  userName: string
  expires_in: number
}

export type AccountImpersonateResponse = {
  impersonated_access_token: string
  impersonation_error: string
}

export type AccountParsedToken = {
  sub: string
  unique_name: string
  sid: string
  jti: string
  iat: number
  role: UserRole
  claims?: unknown | unknown[]
  client_IP: string
  mtaMode: string
  defaultPath: string
  isFullBasket: string
  userId: string
  nbf: number
  exp: number
  iss: string
  aud: string
}

export type SaveUserInfoRequest = {
  userName?: string
  isLocked?: string
}

export type SaveUserInfoResponse = {
  access_token: string
}

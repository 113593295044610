import {
  UserRole,
  useApiCombined,
  useApiManual,
  useLocalStorage,
  useUser,
  useUserFeatureToggles,
} from '@lasso/shared/hooks'
import { Result, arrayify, navigateToAppUrl } from '@lasso/shared/utils'
import { Ref, computed, nextTick, readonly } from 'vue'
import { ApiError } from '@lasso/api-shared'
import { array, string } from 'yup'
import { debouncedRef } from '@vueuse/core'
import { useRoute } from 'vue-router'

import {
  AccountChangePasswordRequestType,
  AccountLoginResponse, AccountParsedToken,
  AccountResetPasswordRequestType,
} from '../../api'
import { useAuthApi } from '../useAuthApi'

import { parseAccessToken } from './utils'
import {
  AuthServerError,
  AuthUnknownError, ChangeEmailError,
  ChangePasswordError,
  ResetPasswordError,
  SendForgotPasswordLinkError,
} from './types'
import { useAuthSso } from './useAuthSso'
import { useAuthEmail } from './useAuthEmail'

/**
 * Composable for working with authorization.
 *
 * Note: the methods returned from this do not throw, instead they return a Result object.
 */
export const useAuth = () => {
  const { userInfo, authInfo, accountInfo, clearUserData, clearFirstTimeUser } = useUser()
  const { updateDefaultPath } = useUserFeatureToggles()
  const api = useAuthApi()
  const route = useRoute()
  const accessToken: Readonly<Ref<AccountParsedToken | null>> = computed(() => {
    if (!authInfo.value) {
      return null
    }

    const rawToken = authInfo.value.token

    try {
      return parseAccessToken(rawToken)
    }
    catch {
      return null
    }
  })

  const authReturnLink = useLocalStorage('authReturnLink', '')

  const setAuthReturnLink = () => {
    if (!route.path.startsWith('/app/auth') && !['/pages/403', '/app/redirect', '/app/auth/change-password'].includes(route.path)) {
      authReturnLink.value = route.path
    }
  }

  const clearAuthReturnLink = () => {
    authReturnLink.value = ''
  }

  const logoutApi = useApiManual(api.logout)
  const revertImpersonationApi = useApiManual(api.revertImpersonation)
  const userInfoApi = useApiManual(api.getUserInfo)
  const accountInfoApi = useApiManual(api.getAccountInfo)
  const impersonateApi = useApiManual(api.impersonate)
  const resetPasswordApi = useApiManual(api.resetPassword)
  const changePasswordApi = useApiManual(api.changePassword)
  const sendForgotPasswordLinkApi = useApiManual(api.sendForgotPasswordLink)
  const saveUserInfoApi = useApiManual(api.saveUserInfo)

  const clearUserDataAndRedirect = async (path = '/app/auth') => {
    clearUserData()
    await nextTick()
    navigateToAppUrl(path)
  }

  const setAuthInfoAndRedirect = async ({ token, name, claims, roles, defaultPath, impersonate = false }: {
    token: string
    name: string
    claims: unknown | unknown[]
    roles: UserRole | UserRole[]
    impersonate?: boolean
    defaultPath: string
  }): Promise<Result<null, AuthUnknownError>> => {
    authInfo.value = {
      token,
      userName: name,
      refreshToken: '',
      userClaims: arrayify(claims),
      userRoles: arrayify(roles),
      useRefreshTokens: false,
      impersonate,
      defaultPath,
    }

    try {
      const [userInfoResponse, accountInfoResponse] = await Promise.all([
        userInfoApi.requestThrows(),
        accountInfoApi.requestThrows(),
      ])

      if (!userInfoResponse || !accountInfoResponse) {
        return Result.err({ code: 'unknown', details: null })
      }

      userInfo.value = userInfoResponse.data
      accountInfo.value = accountInfoResponse.data || null
      const returnLink = authReturnLink.value
      clearAuthReturnLink()

      updateDefaultPath()

      // We let the root app handle this redirect to ensure that the angularjs app is re-mounted,
      // because it can't handle user data changing otherwise
      // TODO: redirect directly to defaultPath when angularjs app is removed
      navigateToAppUrl(returnLink || 'app/redirect')

      return Result.ok(null)
    }
    catch (error) {
      await clearUserDataAndRedirect()
      return Result.err({ code: 'unknown', details: error as Error })
    }
  }

  const setTokenAndRedirect = async (token: AccountLoginResponse): Promise<Result<null, AuthUnknownError>> => {
    let userDetails
    try {
      userDetails = parseAccessToken(token.access_token)
    }
    catch (error) {
      return Result.err({ code: 'unknown', details: error as Error })
    }

    return await setAuthInfoAndRedirect({
      token: token.access_token,
      name: token.userName,
      claims: userDetails.claims,
      roles: userDetails.role,
      defaultPath: userDetails.defaultPath,
    })
  }

  const { getOAuthUrl, loginWithOAuth, ssoApi, loadingSso } = useAuthSso({
    setTokenAndRedirect,
  })
  const { login, loginApi } = useAuthEmail({
    setTokenAndRedirect,
  })

  const { loading: loadingInternal } = useApiCombined([
    loginApi,
    logoutApi,
    revertImpersonationApi,
    userInfoApi,
    accountInfoApi,
    impersonateApi,
    resetPasswordApi,
    changePasswordApi,
    saveUserInfoApi,
    sendForgotPasswordLinkApi,
    ssoApi,
  ])

  const loading = debouncedRef(loadingInternal, 10)

  const logout = async (shouldSetReturnLink = false): Promise<Result<null, null>> => {
    if (shouldSetReturnLink) {
      setAuthReturnLink()
    }

    await logoutApi.request()
    await clearUserDataAndRedirect()
    return Result.ok(null)
  }

  const revertImpersonation = async (): Promise<Result<null, AuthUnknownError>> => {
    let response
    try {
      response = await revertImpersonationApi.requestThrows()
    }
    catch (error) {
      await clearUserDataAndRedirect()
      return Result.err({ code: 'unknown', details: error as Error })
    }

    if (!response) {
      return Result.err({ code: 'unknown', details: null })
    }

    let originalUser
    try {
      originalUser = parseAccessToken(response.data.access_token)
    }
    catch (error) {
      return Result.err({ code: 'unknown', details: error as Error })
    }

    return await setAuthInfoAndRedirect({
      token: response.data.access_token,
      name: originalUser.unique_name,
      claims: [],
      roles: originalUser.role,
      defaultPath: originalUser.defaultPath,
    })
  }

  const impersonate = async (userName: string): Promise<Result<null, AuthUnknownError>> => {
    let response

    try {
      response = await impersonateApi.requestThrows(userName)
    }
    catch (error) {
      return Result.err({ code: 'unknown', details: error as Error })
    }

    if (!response) {
      return Result.err({ code: 'unknown', details: null })
    }

    if (response.data.impersonation_error) {
      return Result.err({ code: 'unknown', details: new Error(response.data.impersonation_error) })
    }

    let impersonatedUser

    try {
      impersonatedUser = parseAccessToken(response.data.impersonated_access_token)
    }
    catch (error) {
      return Result.err({ code: 'unknown', details: error as Error })
    }

    return await setAuthInfoAndRedirect({
      token: response.data.impersonated_access_token,
      name: userName,
      claims: impersonatedUser.claims,
      roles: impersonatedUser.role,
      defaultPath: impersonatedUser.defaultPath,
      impersonate: true,
    })
  }

  const sendForgotPasswordLink = async (email: string): Promise<Result<null, SendForgotPasswordLinkError | AuthUnknownError>> => {
    try {
      const response = await sendForgotPasswordLinkApi.requestThrows({ email })

      if (!response) {
        return Result.err({ code: 'unknown', details: null })
      }

      return Result.ok(null)
    }
    catch (error) {
      if (error instanceof ApiError && error.status === 400) {
        return Result.err({ code: 'incorrectEmail' })
      }
      else {
        return Result.err({ code: 'unknown', details: error as Error })
      }
    }
  }

  const parseServerError = (error: ApiError): AuthServerError | null => {
    if (error.isDataMatching(string().required())) {
      return { code: 'serverError', details: { message: error.data } }
    }
    else if (error.isDataMatching(array().of(string()).required())) {
      return { code: 'serverError', details: { message: error.data.join('. ') } }
    }

    return null
  }

  const resetPassword = async (data: AccountResetPasswordRequestType): Promise<Result<null, ResetPasswordError | AuthUnknownError>> => {
    const invalidTokenErrorSchema = array().of(string().oneOf(['Invalid token.']))

    try {
      const response = await resetPasswordApi.requestThrows(data)

      if (!response) {
        return Result.err({ code: 'unknown', details: null })
      }

      return Result.ok(null)
    }
    catch (error) {
      if (error instanceof ApiError) {
        if (
          error.status === 404
          || (error.status === 401 && error.isDataMatching(invalidTokenErrorSchema))
        ) {
          return Result.err({ code: 'invalidToken' })
        }

        const serverError = parseServerError(error)

        if (serverError) {
          return Result.err(serverError)
        }
      }

      return Result.err({ code: 'unknown', details: error as Error })
    }
  }

  const changePassword = async (data: AccountChangePasswordRequestType): Promise<Result<null, ChangePasswordError | AuthUnknownError>> => {
    try {
      const response = await changePasswordApi.requestThrows(data)

      if (!response) {
        return Result.err({ code: 'unknown', details: null })
      }

      clearFirstTimeUser()

      return Result.ok(null)
    }
    catch (error) {
      if (error instanceof ApiError) {
        const serverError = parseServerError(error)

        if (serverError) {
          if (serverError.details.message === 'Incorrect password.') {
            return Result.err({ code: 'incorrectPassword', details: serverError.details })
          }

          return Result.err(serverError)
        }
      }

      return Result.err({ code: 'unknown', details: error as Error })
    }
  }

  const changeEmail = async (email: string): Promise<Result<null, ChangeEmailError | AuthUnknownError>> => {
    try {
      const response = await saveUserInfoApi.requestThrows({ userName: email })

      if (!response) {
        return Result.err({ code: 'unknown', details: null })
      }

      if (authInfo.value) {
        authInfo.value = {
          ...authInfo.value,
          userName: email,
          token: response.data.access_token,
        }
      }

      if (userInfo.value) {
        userInfo.value = {
          ...userInfo.value,
          email,
        }
      }

      return Result.ok(null)
    }
    catch (error) {
      if (error instanceof ApiError) {
        const serverError = parseServerError(error)

        if (serverError) {
          return Result.err(serverError)
        }
      }

      return Result.err({ code: 'unknown', details: error as Error })
    }
  }

  return {
    login,
    loginWithOAuth,
    logout,
    getOAuthUrl,
    revertImpersonation,
    impersonate,
    sendForgotPasswordLink,
    resetPassword,
    changePassword,
    changeEmail,
    loading,
    loadingSso,
    authReturnLink: readonly(authReturnLink),
    setAuthReturnLink,
    clearAuthReturnLink,
    accessToken,
  }
}

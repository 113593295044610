import { ApiService } from '@lasso/api-shared'
import { AccountInfo, UserInfo } from '@lasso/shared/hooks'
import { AxiosResponse } from 'axios'

import {
  AccountChangePasswordRequestType,
  AccountImpersonateResponse,
  AccountLoginRequestType, AccountLoginResponse, AccountOAuthProviderEnum,
  AccountResetPasswordRequestType,
  AccountSendForgotPasswordRequestType,
  RevertImpersonationResponse, SaveUserInfoRequest, SaveUserInfoResponse,
} from '../types'

export class AccountService extends ApiService {
  getUserInfo(): Promise<AxiosResponse<UserInfo>> {
    return this.request({
      url: 'api/account/userInfo',
      method: 'GET',
    })
  }

  getAccountInfo(): Promise<AxiosResponse<AccountInfo | ''>> {
    return this.request({
      url: 'api/companyAccount',
      method: 'GET',
    })
  }

  login(data: AccountLoginRequestType): Promise<AxiosResponse<AccountLoginResponse>> {
    const formData = new FormData()
    formData.append('grant_type', 'password')
    formData.append('username', data.userName)
    formData.append('password', data.password)

    return this.request({
      url: 'api/account/token',
      method: 'POST',
      data: formData,
      headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
    })
  }

  logout(): Promise<AxiosResponse<''>> {
    return this.request({
      url: 'api/account/logout',
      method: 'POST',
    })
  }

  sendForgotPasswordLink(data: AccountSendForgotPasswordRequestType): Promise<AxiosResponse<''>> {
    return this.request({
      url: 'api/account/SendForgotPasswordLink',
      method: 'POST',
      data,
    })
  }

  resetPassword(data: AccountResetPasswordRequestType): Promise<AxiosResponse<''>> {
    return this.request({
      url: 'api/account/ResetPassword',
      method: 'POST',
      data,
    })
  }

  changePassword(data: AccountChangePasswordRequestType): Promise<AxiosResponse<''>> {
    return this.request({
      url: 'api/account/changePassword',
      method: 'POST',
      data,
    })
  }

  getOAuthClientId(provider: AccountOAuthProviderEnum): Promise<AxiosResponse<string>> {
    return this.request<string>({
      url: `api/account/GetOAuthClientId/${provider}`,
      method: 'GET',
      responseType: 'text',
    })
  }

  signInWithOAuthCode(provider: AccountOAuthProviderEnum, code: string): Promise<AxiosResponse<''>> {
    return this.request({
      url: `api/account/SignInWithOAuthCode/${provider}`,
      method: 'POST',
      headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
      data: `code=${encodeURIComponent(code)}`,
    })
  }

  revertImpersonation(): Promise<AxiosResponse<RevertImpersonationResponse>> {
    return this.request({
      url: 'api/account/revertImpersonation',
      method: 'POST',
    })
  }

  impersonate(userName: string): Promise<AxiosResponse<AccountImpersonateResponse>> {
    return this.request({
      url: 'api/account/Impersonate',
      method: 'POST',
      data: { userName },
    })
  }

  saveUserInfo(data: SaveUserInfoRequest): Promise<AxiosResponse<SaveUserInfoResponse>> {
    return this.request({
      url: 'api/user/saveUserInfo',
      method: 'POST',
      data,
    })
  }
}
